// Asset constants
export const ARROW_LEFT = "/static/images/homepage/arrow-left.svg";
export const ARROW_RIGHT = "/static/images/homepage/arrow-right.svg";
export const ASURANSI_KARYAWAN_ICON = "/static/images/homepage/asuransi-karyawan.svg";
export const ASURANSI_KESEHATAN_ICON = "/static/images/homepage/asuransi-kesehatan.svg";
export const ASURANSI_MOBIL_ICON = "/static/images/homepage/asuransi-mobil.svg";
export const ASURANSI_MOTOR_ICON = "/static/images/homepage/asuransi-motor.svg";
export const BRAND_ACA_ICON = { icon: "/static/images/homepage/brand-aca.svg", url: '/asuransi/mobil-aca/' };
export const BRAND_APRIL_ICON = { icon: "/static/images/homepage/brand-april.svg", url: '/asuransi/april-international/' };
export const BRAND_ASTRA_ICON = { icon: "/static/images/homepage/brand-astra.svg", url: '/asuransi/mobil-garda-oto/' };
export const BRAND_AXA_MANDIRI_ICON = { icon: "/static/images/homepage/brand-axa-mandiri.svg", url: '/asuransi/kesehatan-axa/' };
export const BRAND_CAR_LIFE_ICON = { icon: "/static/images/homepage/brand-car-life.svg", url: '/asuransi/kesehatan-car/' };
export const BRAND_LGI_ICON = { icon: "/static/images/homepage/brand-lgi.svg", url: '/asuransi/kesehatan-lippo/' };
export const BRAND_MAG_ICON = { icon: "/static/images/homepage/brand-mag.svg", url: '/asuransi/mag/' };
export const BRAND_MEGA_ICON = { icon: "/static/images/homepage/brand-mega.svg", url: '/asuransi/mobil-mega-insurance/' };
export const BRAND_SIMAS_INSURTECH_ICON = { icon: "/static/images/homepage/brand-simas-insurtech.svg", url: '/asuransi/simas-insurtech/' };
export const BRAND_ASURANSI_SINARMAS = { icon: "/static/images/homepage/brand-asuransi-sinarmas.svg", url: '/asuransi/kesehatan-sinar-mas/' };
export const BRAND_SOMPO_BY_ROOJAI_ICON = { icon: "/static/images/homepage/brand-sompo-by-roojai.svg", url: '/asuransi/mobil-sompo/' };
export const BRAND_SUN_LIFE_ICON = { icon: "/static/images/homepage/brand-sun-life.svg", url: '/asuransi/sunlife/' };
export const BRAND_ZURICH_SYARIAH_ICON = { icon: "/static/images/homepage/brand-zurich-syariah.svg", url: '/asuransi/zurich-syariah/' };
export const CALL_ME_CHEETAH_IMAGE = '/static/images/call-me-cheetah.svg';
export const CHECK_CIRCLE_SOLID_ICON = "/static/images/homepage/check-circle-solid.svg";
export const HEADSET_ICON = '/static/images/homepage/headset.svg';
export const MASCOT_CHEETAH_IMAGE = "/static/images/homepage/mascot-cheetah.svg";
export const MASCOT_CIRCLE_AVATAR_ICON = '/static/images/icons/mascot-circle-avatar.svg';
export const MINUS_ICON = "/static/images/how-to-buy/minus-icon.svg";
export const OUTLINE_CHECK_ACTIVE_ICON = '/static/images/icons/outline-check-active.svg';
export const OUTLINE_CHECK_ICON = '/static/images/icons/outline-check.svg';
export const PLUS_ICON = "/static/images/how-to-buy/plus-icon.svg";
export const RECOMMENDED_ARTICLE_1 = "/static/images/homepage/recommended-article-1.jpg";
export const RECOMMENDED_ARTICLE_2 = "/static/images/homepage/recommended-article-2.jpg";
export const RECOMMENDED_ARTICLE_3 = "/static/images/homepage/recommended-article-3.jpg";
export const RECOMMENDED_ARTICLE_4 = "/static/images/homepage/recommended-article-4.jpg";
export const RED_ALERT_ICON = "/static/images/icons/red-alert.svg";
export const WHY_LIFEPAL_DISCOUNT_ICON = "/static/images/homepage/why-lifepal-discount.svg";
export const WHY_LIFEPAL_FREE_ICON = "/static/images/homepage/why-lifepal-free.svg";
export const WHY_LIFEPAL_RECOMMENDED_ICON = "/static/images/homepage/why-lifepal-recommended.svg";
export const WHY_LIFEPAL_RELIABLE_ICON = "/static/images/homepage/why-lifepal-reliable.svg";
export const WHY_LIFEPAL_TIME_ICON = "/static/images/homepage/why-lifepal-time.svg";

// Associated brands
export const ASSOCIATED_BRANDS = [
  BRAND_ASTRA_ICON,
  BRAND_ZURICH_SYARIAH_ICON,
  BRAND_ACA_ICON,
  BRAND_SOMPO_BY_ROOJAI_ICON,
  BRAND_MEGA_ICON,
  BRAND_SIMAS_INSURTECH_ICON,
  BRAND_ASURANSI_SINARMAS,
  BRAND_AXA_MANDIRI_ICON,
  BRAND_LGI_ICON,
  BRAND_APRIL_ICON,
  BRAND_MAG_ICON,
  BRAND_CAR_LIFE_ICON,
];

//Media
export const MEDIA_WEB = "/static/images/homepage/media-web.svg";
export const MEDIA_MOBILE = "/static/images/homepage/media-mobile.svg";

// Review Google JSON
export const REVIEW_LIFEPAL_JSON = [
  {
      id: 1,
      alt: 'review',
      name: 'Irfan Prayogi',
      review: `
          Konsultasi Fast Respon,dan Ramah
          Proses Cepat Dan Mudah
          Cek kendaraan Bisa Via Video Call,
          Recomendasi Buat teman2 semua pakai Lifepal.
        `,
  },
  {
      id: 2,
      alt: 'review',
      name: 'Yoga Dwi Haryoko',
      review: `
          Prosesnya sangat mudah dan cepat, CS Lifepal juga sangat resposif dan membantu..Recommend deh..Pertahankan pelayanan ke customernya.
        `,
  },
  {
      id: 3,
      alt: 'review',
      name: 'Puji Rahmat',
      review: `
          Proses pengajuannya mudah dan cepat ... Preminya juga kompetitif ... Pengecekan kondisi mobil melalui video call dan Petugasnya ramah ... Mantapp deh ... Terima kasih ...
        `,
  },
  {
      id: 4,
      alt: 'review',
      name: 'Jeremy Adam',
      review: `
          Service nya bagus. Tidak di spam call. By Whatsapp message semua (diluar survey asuransi kendaraan) best. Beda sama yg lain. Dipertahankan.
        `,
  },
  {
      id: 5,
      alt: 'review',
      name: 'Sidik Marsudi',
      review: `
          Pelayanan Lifepal sangat baik, mulai dari penawaran jenis jenis asuransi sampai terbitnya polis asuransi. Semuanya serba cepat. Check fisik kendaraan juga tepat waktu dan dilakukan dengan online lagi. Luar biasa.
        `,
  },
  {
      id: 6,
      alt: 'review',
      name: 'Mukhamad Mardiansyah',
      review: `
          Terima kasih atas layanan Lifepal! Proses pembelian asuransi sangat mudah, banyak pilihan dari berbagai perusahaan, dan harga yang transparan. Tim customer service juga membantu menjawab semua pertanyaan saya. Sangat direkomendasikan bagi yang ingin mencari asuransi dengan cepat dan tanpa ribet!
        `,
  },
  {
      id: 7,
      alt: 'review',
      name: 'Andreas Iwan Setianto',
      review: `
          Marketingnya memberikan penawaran yg baik, dimana kita diberi beberapa alternatif pilihan asuransi yg tepat. Proses pembukaan polis sangat mudah, survei juga praktis hanya lewat video call. Dan masih ada bonus menarik yang diberikan oleh Lifepal. Terima kasih Lifepal 🙏
        `,
  },
];

// Why Lifepal JSON
export const WHY_LIFEPAL_JSON = [
  {
    icon: WHY_LIFEPAL_DISCOUNT_ICON,
    title: "Beneran Hemat",
    description:
      "Lifepal menyediakan pilihan asuransi terkemuka paling lengkap di Indonesia. Cukup jawab beberapa pertanyaan dan dapatkan polis terbaik dengan premi termurah.",
  },
  {
    icon: WHY_LIFEPAL_RECOMMENDED_ICON,
    title: "Beneran Netral",
    description:
      "Konsultan Lifepal akan memberikan plan asuransi terbaik yang paling sesuai dengan kebutuhan dan anggaran kamu. Layanan konsultasi gratis!",
  },
  {
    icon: WHY_LIFEPAL_FREE_ICON,
    title: "Beneran Ngebantu",
    description:
      "Lifepal dapat membantu pastikan proses klaim yang diberikan oleh pihak asuransi sesuai dengan yang dijanjikan. Cukup hubungi kami dan Lifepal siap membantumu!",
  },
  {
    icon: WHY_LIFEPAL_TIME_ICON,
    title: "Beneran Cepet",
    description:
      "Mulai dari bandingkan asuransi hingga polis diterbitkan, Lifepal menggunakan teknologi untuk membuat prosesnya lebih cepat dan mudah untuk kamu.",
  },
  {
    icon: WHY_LIFEPAL_RELIABLE_ICON,
    title: "Beneran Terpercaya",
    description:
      "Memiliki ulasan positif dari nasabah dengan rating 4,5 di Google Review. Berlisensi dan berada di bawah pengawasan OJK.",
  },
];

// Recommended articles
export const RECOMMENDED_ARTICLES = [
  {
    image: RECOMMENDED_ARTICLE_1,
    title: "9 Asuransi Mobil All Risk Terbaik yang Bisa Jadi Pilihan",
    label: "Asuransi Mobil",
    date: "15 Desember 2023",
    link: "https://lifepal.co.id/media/asuransi-mobil/asuransi-mobil-all-risk-terbaik/",
  },
  {
    image: RECOMMENDED_ARTICLE_2,
    title: "Biaya Asuransi Mobil All Risk dan TLO Menurut OJK",
    label: "Asuransi Mobil",
    date: "22 Januari 2024",
    link: "https://lifepal.co.id/media/asuransi-mobil/rate-asuransi-mobil/",
  },
  {
    image: RECOMMENDED_ARTICLE_3,
    title: "Rekomendasi Asuransi Mobil Murah Terbaik di Indonesia",
    label: "Asuransi Mobil",
    date: "5 Mei 2023",
    link: "https://lifepal.co.id/media/asuransi-mobil/pilihan-asuransi-mobil-murah/",
  },
  {
    image: RECOMMENDED_ARTICLE_4,
    title: "Kenali Jenis-Jenis Asuransi Mobil dan Manfaat Selengkapnya!",
    label: "Asuransi Mobil",
    date: "30 September 2024",
    link: "https://lifepal.co.id/media/asuransi-mobil/perbandingan-asuransi-mobil/",
  },
];

// How to buy static items
export const HOW_TO_BUY = {
  car: {
    title: "Langkah-Langkah Beli Asuransi Mobil Online",
    content: [
      {
        text: "Kunjungi situs kami di Lifepal.co.id dan pilih produk <b>Asuransi Mobil</b>.",
        image: "/static/images/how-to-buy/car.svg",
      },
      {
        text: "Isi formulir dan jawab beberapa pertanyaan untuk bantu kami memberikan rekomendasi terbaik sesuai profil mobilmu.",
        image: "/static/images/how-to-buy/form.svg",
      },
      {
        text: "Tim Lifepal akan segera menghubungi kamu untuk memberikan penawaran dan perbandingkan polis terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/compare.svg",
      },
      {
        text: "Pilih polis yang diinginkan.",
        image: "/static/images/how-to-buy/buy.svg",
      },
      {
        text: "Quotation dan payment link akan segera dikirimkan ke alamat email kamu.",
        image: "/static/images/how-to-buy/checkout.svg",
      },
      {
        text: "Klik payment link dan selesaikan pembayaran.",
        image: "/static/images/how-to-buy/pay.svg",
      },
      {
        text: "Proses inspeksi mobil dilakukan secara online melalui video call.",
        image: "/static/images/how-to-buy/inspect.svg",
      },
      {
        text: "Polis asuransi mobil kamu segera kami proses.",
        image: "/static/images/how-to-buy/process.svg",
      },
    ],
    documents:
      "- Kartu Tanda Pengenal (KTP)\n- Surat Izin Mengemudi (SIM)\n- Surat Tanda Nomor Kendaraan (STNK)",
  },
  health: {
    title: "Langkah-Langkah Beli Asuransi Kesehatan Online",
    content: [
      {
        text: "Kunjungi situs kami di Lifepal.co.id dan pilih produk <b>Asuransi Kesehatan</b>.",
        image: "/static/images/how-to-buy/health.svg",
      },
      {
        text: "Isi formulir dan jawab beberapa pertanyaan untuk bantu kami memberikan rekomendasi terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/form.svg",
      },
      {
        text: "Tim Lifepal akan segera menghubungi kamu untuk memberikan penawaran dan perbandingkan polis terbaik sesuai kebutuhanmu.",
        image: "/static/images/how-to-buy/compare.svg",
      },
      {
        text: "Pilih polis yang diinginkan.",
        image: "/static/images/how-to-buy/buy.svg",
      },
      {
        text: "Quotation dan payment link akan segera dikirimkan ke alamat email kamu.",
        image: "/static/images/how-to-buy/checkout.svg",
      },
      {
        text: "Klik payment link dan selesaikan pembayaran.",
        image: "/static/images/how-to-buy/pay.svg",
      },
      {
        text: "Polis asuransi kesehatan kamu segera kami proses.",
        image: "/static/images/how-to-buy/process.svg",
      },
    ],
    documents:
      "- Kartu Tanda Pengenal (KTP)\n- Kartu Keluarga\n- Passport (khusus untuk WNA)\n- KITAS (khusus untuk WNA)",
  },
};

// FAQ Items
export const FAQ_ITEMS = [
  {
    title: "Apa itu Lifepal?",
    content: () => (
      <p className="faq-item-content">
        Lifepal adalah marketplace asuransi yang berdiri sejak tahun 2018 dengan
        misi untuk memberikan pilihan asuransi berkualitas tinggi yang paling
        lengkap di Indonesia. Kami berkomitmenuntuk membantu nasabah membuat
        keputusan asuransi yang tepat, sesuai dengan anggaran dan kebutuhan
        mereka.
      </p>
    ),
  },
  {
    title:
      "Apa bedanya marketplace asuransi Lifepal dengan perusahaan asuransi?",
    content: () => (
      <p className="faq-item-content">
        Sebagai marketplace asuransi, Lifepal bertindak sebagai perantara antara
        nasabah dan perusahaan asuransi. Karena itu, Lifepal memberikan panduan
        dan rekomendasi yang objektif, sehingga dapat membantu setiap calon
        nasabah mendapatkan proteksi asuransi terbaik yang lebih personal.
      </p>
    ),
  },
  {
    title: "Bagaimana Lifepal mendapatkan keuntungan?",
    content: () => (
      <p className="faq-item-content">
        Lifepal menerima komisi dari perusahaan asuransi untuk setiap polis yang
        berhasil dijual. Komisi ini berupa persentase dari premi polis yang
        dibayar oleh nasabah. Besaran komisi disesuaikan dengan perjanjian
        antara pihak Lifepal sebagai marketplace asuransi dan perusahaan
        asuransi.
      </p>
    ),
  },
  {
    title: "Apakah Lifepal sudah terdaftar di Otoritas Jasa Keuangan (OJK)?",
    content: () => (
      <p className="faq-item-content">
        Tentu, Lifepal beroperasi di bawah izin PT Anugrah Atma Adiguna yang
        sudah terdaftar dan diawasi oleh OJK sesuai KEP-018/KMK.17/1992 dan
        anggota APPARINDO 60-2001/APPARINDO/2024.
      </p>
    ),
  },
  {
    title: "Bagaimana cara membeli dan daftar asuransi online melalui Lifepal?",
    content: () => (
      <p className="faq-item-content">
        Berikut ini adalah langkah-langkah{" "}
        <a href="https://lifepal.co.id/cara-beli/">
          membeli asuransi secara online
        </a>{" "}
        di Lifepal.
        <ul>
          <li>
            Langkah 1: Kunjungi situs lifepal.co.id, pilih jenis asuransi yang
            diinginkan.
          </li>
          <li>
            Langkah 2: Isi formulir data diri atau profil kendaraan untuk produk
            asuransi mobil. Kemudian klik Tampilkan Pilihan Polis.
          </li>
          <li>
            Langkah 3: Tim Lifepal akan segera menghubungimu untuk memberikan
            penawaran dan perbandingan polis yang sesuai dengan profilmu.
          </li>
          <li>Langkah 4: Pilih polis yang diinginkan.</li>
          <li>
            Langkah 5: Quotation dan payment link akan segera dikirimkan ke
            alamat email kamu.
          </li>
          <li>Langkah 6: Klik payment link dan selesaikan pembayaran.</li>
          <li>
            Langkah 7: Khusus untuk produk asuransi mobil, akan dilakukan proses
            inspeksi secara online.
          </li>
          <li>
            Langkah 8: Polis kamu segera kami proses dan akan dikirim melalui
            email.
          </li>
        </ul>
      </p>
    ),
  },
];

export const HOMEPAGE_PROMO = [
  {
    id: 0,
    imgURL: '/static/images/promos/home_banner1.jpg',
    fallbackImgURL: '/static/images/promos/home_banner1.jpg',
    alt: 'Montly Promo',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=Generic_MV',
  },
  {
    id: 1,
    imgURL: '/static/images/promos/home_banner2.png',
    fallbackImgURL: '/static/images/promos/home_banner2.png',
    alt: 'Promo Asuransi Kesehatan',
    redirectURL: '/asuransi/kesehatan/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_kesehatan',
  },
  {
    id: 2,
    imgURL: '/static/images/promos/home_banner3.png',
    fallbackImgURL: '/static/images/promos/home_banner3.png',
    alt: 'Promo 15 Tahun',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_promo_voucher',
  },
  {
    id: 3,
    imgURL: '/static/images/promos/home_banner4.png',
    fallbackImgURL: '/static/images/promos/home_banner4.png',
    alt: 'Promo Asuransi Kesehatan Keluarga',
    redirectURL: '/asuransi/kesehatan-keluarga/?utm_source=homepage&utm_medium=banner&utm_campaign=promo_asuransi_keluarga',
  },
  {
    id: 4,
    imgURL: '/static/images/promos/home_banner5.png',
    fallbackImgURL: '/static/images/promos/home_banner5.png',
    alt: 'Bandingkan Asuransi Terbaik',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=homepage+compare',
  },
  {
    id: 5,
    imgURL: '/static/images/promos/home_banner6.png',
    fallbackImgURL: '/static/images/promos/home_banner6.png',
    alt: 'Perbaikan di Bengkel Resmi',
    redirectURL: '/asuransi/mobil/?utm_source=homepage&utm_medium=banner&utm_campaign=MV_cicilan',
  }
]

export const COMPANY_VALUE = [
  {
    title: "Mengutamakan Nasabah (Customer First)",
    subtitle:
      "Lifepal memastikan bahwa setiap produk dan layanan yang kami berikan dirancang dengan mempertimbangkan nasabah. Dengan terus berinovasi, Lifepal secara konsisten memberikan pengalaman terbaik dalam menghadirkan proteksi asuransi yang dapat dipersonalisasi untuk memenuhi kebutuhan setiap nasabah. ",
    image: "/static/images/company_value_1.png",
  },
  {
    title: "Rasa Kepemilikan (Ownership)",
    subtitle:
      "Lifepal berdedikasi untuk memelihara kepercayaan nasabah dengan senantiasa memberikan layanan terbaik sepenuh hati. Dengan tim yang kuat dan berpengalaman dibidangnya, Lifepal memastikan setiap kebutuhan nasabah dapat dipenuhi dengan tanggung jawab serta dedikasi tinggi.",
    image: "/static/images/company_value_2.png",
  },
  {
    title: "Integritas (Integrity)",
    subtitle:
      "Lifepal menghargai kepercayaan yang diberikan nasabah kepada kami. Karena itu, kami terus berusaha untuk selalu transparan dan secara konsisten selaras dengan nilai-nilai yang kami pegang dalam setiap interaksi dengan nasabah.",
    image: "/static/images/company_value_3.png",
  },
];

export const BEHIND_LIFEPAL = [
  {
    name: "Eko Waluyo",
    position: "Presiden Direktur",
    text: "Eko Waluyo memiliki pengalaman lebih dari 10 tahun dalam memimpin sejumlah perusahaan broker asuransi ternama di Indonesia. Sukses memimpin Rajawali Insurance Brokers dan Nusantara Insurance Broker & Consultant, kini Eko Waluyo menjabat sebagai Presiden Direktur  dari PT Anugrah Atma Adiguna.  ",
    image: "/static/images/behind-lifepal-1.svg",
  },
  {
    name: "Bambang ROCHYARSYAM",
    position: "Direktur",
    text: "Bambang Rochyarsyam memiliki pengalaman lebih dari 30 tahun dalam industri asuransi di Indonesia. Dengan latar belakang memimpin berbagai perusahaan asuransi, termasuk Berdikari Insurance dan Rajawali Insurance Brokers, saat ini Bambang Rochyarsyam menjabat sebagai Direktur di PT Anugrah Atma Adiguna. ",
    image: "/static/images/behind-lifepal-2.svg",
  },
];

export const USP_PANEL = {
  auto: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Diskon dan reward menarik dari 50+ pilihan Asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/car.svg",
      text: "800+ bengkel rekanan di seluruh Indonesia, sparepart original!",
      alt: "bengkel rekanan"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Pengaktifan polis cepat < 10 menit dengan inspeksi kendaraan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ],
  moto: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Bandingkan antara 13+ pilihan asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Pengaktifan polis cepat dengan inspeksi kendaraan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ],
  others: [
    {
      image: "/static/images/icons/type-active.svg",
      text: "Bandingkan antara 13+ pilihan asuransi",
      alt: "diskon dan reward"
    },
    {
      image: "/static/images/icons/office-building.svg",
      text: "Rumah sakit rekanan tersebar di seluruh Indonesia",
      alt: "bengkel rekanan"
    },
    {
      image: "/static/images/icons/mdi_car-clock.svg",
      text: "Mulai dari membandingkan asuransi, hingga polis terbit, semuanya bisa dilakukan online",
      alt: "avatar"
    },
    {
      image: "/static/images/homepage/headset.svg",
      text: "Konsultasi gratis dengan konsultan berpengalaman",
      alt: "avatar"
    }
  ]
}

export const COUNTRIES = [
  {
    "label": "Afghanistan",
    "code": "AFG"
  },
  {
    "label": "Albania",
    "code": "ALB"
  },
  {
    "label": "Algeria",
    "code": "DZA"
  },
  {
    "label": "Andorra",
    "code": "AND"
  },
  {
    "label": "Angola",
    "code": "AGO"
  },
  {
    "label": "Antigua and Barbuda",
    "code": "ATG"
  },
  {
    "label": "Argentina",
    "code": "ARG"
  },
  {
    "label": "Armenia",
    "code": "ARM"
  },
  {
    "label": "Australia",
    "code": "AUS"
  },
  {
    "label": "Austria",
    "code": "AUT"
  },
  {
    "label": "Azerbaijan",
    "code": "AZE"
  },
  {
    "label": "Bahamas",
    "code": "BHS"
  },
  {
    "label": "Bahrain",
    "code": "BHR"
  },
  {
    "label": "Bangladesh",
    "code": "BGD"
  },
  {
    "label": "Barbados",
    "code": "BRB"
  },
  {
    "label": "Belarus",
    "code": "BLR"
  },
  {
    "label": "Belgium",
    "code": "BEL"
  },
  {
    "label": "Belize",
    "code": "BLZ"
  },
  {
    "label": "Benin",
    "code": "BEN"
  },
  {
    "label": "Bhutan",
    "code": "BTN"
  },
  {
    "label": "Bolivia",
    "code": "BOL"
  },
  {
    "label": "Bosnia and Herzegovina",
    "code": "BIH"
  },
  {
    "label": "Botswana",
    "code": "BWA"
  },
  {
    "label": "Brazil",
    "code": "BRA"
  },
  {
    "label": "Brunei",
    "code": "BRN"
  },
  {
    "label": "Bulgaria",
    "code": "BGR"
  },
  {
    "label": "Burkina Faso",
    "code": "BFA"
  },
  {
    "label": "Burundi",
    "code": "BDI"
  },
  {
    "label": "Cabo Verde",
    "code": "CPV"
  },
  {
    "label": "Cambodia",
    "code": "KHM"
  },
  {
    "label": "Cameroon",
    "code": "CMR"
  },
  {
    "label": "Canada",
    "code": "CAN"
  },
  {
    "label": "Central African Republic",
    "code": "CAF"
  },
  {
    "label": "Chad",
    "code": "TCD"
  },
  {
    "label": "Chile",
    "code": "CHL"
  },
  {
    "label": "China",
    "code": "CHN"
  },
  {
    "label": "Colombia",
    "code": "COL"
  },
  {
    "label": "Comoros",
    "code": "COM"
  },
  {
    "label": "Congo",
    "code": "COG"
  },
  {
    "label": "Costa Rica",
    "code": "CRI"
  },
  {
    "label": "Côte d'Ivoire",
    "code": "CIV"
  },
  {
    "label": "Croatia",
    "code": "HRV"
  },
  {
    "label": "Cuba",
    "code": "CUB"
  },
  {
    "label": "Cyprus",
    "code": "CYP"
  },
  {
    "label": "Czech Republic (Czechia)",
    "code": "CZE"
  },
  {
    "label": "Denmark",
    "code": "DNK"
  },
  {
    "label": "Djibouti",
    "code": "DJI"
  },
  {
    "label": "Dominica",
    "code": "DMA"
  },
  {
    "label": "Dominican Republic",
    "code": "DOM"
  },
  {
    "label": "DR Congo",
    "code": "COD"
  },
  {
    "label": "Ecuador",
    "code": "ECU"
  },
  {
    "label": "Egypt",
    "code": "EGY"
  },
  {
    "label": "El Salvador",
    "code": "SLV"
  },
  {
    "label": "Equatorial Guinea",
    "code": "GNQ"
  },
  {
    "label": "Eritrea",
    "code": "ERI"
  },
  {
    "label": "Estonia",
    "code": "EST"
  },
  {
    "label": "Eswatini",
    "code": "SWZ"
  },
  {
    "label": "Ethiopia",
    "code": "ETH"
  },
  {
    "label": "Fiji",
    "code": "FJI"
  },
  {
    "label": "Finland",
    "code": "FIN"
  },
  {
    "label": "France",
    "code": "FRA"
  },
  {
    "label": "Gabon",
    "code": "GAB"
  },
  {
    "label": "Gambia",
    "code": "GMB"
  },
  {
    "label": "Georgia",
    "code": "GEO"
  },
  {
    "label": "Germany",
    "code": "DEU"
  },
  {
    "label": "Ghana",
    "code": "GHA"
  },
  {
    "label": "Greece",
    "code": "GRC"
  },
  {
    "label": "Grenada",
    "code": "GRD"
  },
  {
    "label": "Guatemala",
    "code": "GTM"
  },
  {
    "label": "Guinea",
    "code": "GIN"
  },
  {
    "label": "Guinea-Bissau",
    "code": "GNB"
  },
  {
    "label": "Guyana",
    "code": "GUY"
  },
  {
    "label": "Haiti",
    "code": "HTI"
  },
  {
    "label": "Holy See",
    "code": "VAT"
  },
  {
    "label": "Honduras",
    "code": "HND"
  },
  {
    "label": "Hungary",
    "code": "HUN"
  },
  {
    "label": "Iceland",
    "code": "ISL"
  },
  {
    "label": "India",
    "code": "IND"
  },
  {
    "label": "Indonesia",
    "code": "IDN"
  },
  {
    "label": "Iran",
    "code": "IRN"
  },
  {
    "label": "Iraq",
    "code": "IRQ"
  },
  {
    "label": "Ireland",
    "code": "IRL"
  },
  {
    "label": "Israel",
    "code": "ISR"
  },
  {
    "label": "Italy",
    "code": "ITA"
  },
  {
    "label": "Jamaica",
    "code": "JAM"
  },
  {
    "label": "Japan",
    "code": "JPN"
  },
  {
    "label": "Jordan",
    "code": "JOR"
  },
  {
    "label": "Kazakhstan",
    "code": "KAZ"
  },
  {
    "label": "Kenya",
    "code": "KEN"
  },
  {
    "label": "Kiribati",
    "code": "KIR"
  },
  {
    "label": "Kuwait",
    "code": "KWT"
  },
  {
    "label": "Kyrgyzstan",
    "code": "KGZ"
  },
  {
    "label": "Laos",
    "code": "LAO"
  },
  {
    "label": "Latvia",
    "code": "LVA"
  },
  {
    "label": "Lebanon",
    "code": "LBN"
  },
  {
    "label": "Lesotho",
    "code": "LSO"
  },
  {
    "label": "Liberia",
    "code": "LBR"
  },
  {
    "label": "Libya",
    "code": "LBY"
  },
  {
    "label": "Liechtenstein",
    "code": "LIE"
  },
  {
    "label": "Lithuania",
    "code": "LTU"
  },
  {
    "label": "Luxembourg",
    "code": "LUX"
  },
  {
    "label": "Madagascar",
    "code": "MDG"
  },
  {
    "label": "Malawi",
    "code": "MWI"
  },
  {
    "label": "Malaysia",
    "code": "MYS"
  },
  {
    "label": "Maldives",
    "code": "MDV"
  },
  {
    "label": "Mali",
    "code": "MLI"
  },
  {
    "label": "Malta",
    "code": "MLT"
  },
  {
    "label": "Marshall Islands",
    "code": "MHL"
  },
  {
    "label": "Mauritania",
    "code": "MRT"
  },
  {
    "label": "Mauritius",
    "code": "MUS"
  },
  {
    "label": "Mexico",
    "code": "MEX"
  },
  {
    "label": "Micronesia",
    "code": "FSM"
  },
  {
    "label": "Moldova",
    "code": "MDA"
  },
  {
    "label": "Monaco",
    "code": "MCO"
  },
  {
    "label": "Mongolia",
    "code": "MNG"
  },
  {
    "label": "Montenegro",
    "code": "MNE"
  },
  {
    "label": "Morocco",
    "code": "MAR"
  },
  {
    "label": "Mozambique",
    "code": "MOZ"
  },
  {
    "label": "Myanmar",
    "code": "MMR"
  },
  {
    "label": "Namibia",
    "code": "NAM"
  },
  {
    "label": "Nauru",
    "code": "NRU"
  },
  {
    "label": "Nepal",
    "code": "NPL"
  },
  {
    "label": "Netherlands",
    "code": "NLD"
  },
  {
    "label": "New Zealand",
    "code": "NZL"
  },
  {
    "label": "Nicaragua",
    "code": "NIC"
  },
  {
    "label": "Niger",
    "code": "NER"
  },
  {
    "label": "Nigeria",
    "code": "NGA"
  },
  {
    "label": "North Korea",
    "code": "PRK"
  },
  {
    "label": "North Macedonia",
    "code": "MKD"
  },
  {
    "label": "Norway",
    "code": "NOR"
  },
  {
    "label": "Oman",
    "code": "OMN"
  },
  {
    "label": "Pakistan",
    "code": "PAK"
  },
  {
    "label": "Palau",
    "code": "PLW"
  },
  {
    "label": "Panama",
    "code": "PAN"
  },
  {
    "label": "Papua New Guinea",
    "code": "PNG"
  },
  {
    "label": "Paraguay",
    "code": "PRY"
  },
  {
    "label": "Peru",
    "code": "PER"
  },
  {
    "label": "Philippines",
    "code": "PHL"
  },
  {
    "label": "Poland",
    "code": "POL"
  },
  {
    "label": "Portugal",
    "code": "PRT"
  },
  {
    "label": "Qatar",
    "code": "QAT"
  },
  {
    "label": "Romania",
    "code": "ROM"
  },
  {
    "label": "Russia",
    "code": "RUS"
  },
  {
    "label": "Rwanda",
    "code": "RWA"
  },
  {
    "label": "Saint Kitts & Nevis",
    "code": "KNA"
  },
  {
    "label": "Saint Lucia",
    "code": "LCA"
  },
  {
    "label": "Samoa",
    "code": "WSM"
  },
  {
    "label": "San Marino",
    "code": "SMR"
  },
  {
    "label": "Sao Tome & Principe",
    "code": "STP"
  },
  {
    "label": "Saudi Arabia",
    "code": "SAU"
  },
  {
    "label": "Senegal",
    "code": "SEN"
  },
  {
    "label": "Serbia",
    "code": "SRB"
  },
  {
    "label": "Seychelles",
    "code": "SYC"
  },
  {
    "label": "Sierra Leone",
    "code": "SLE"
  },
  {
    "label": "Singapore",
    "code": "SGP"
  },
  {
    "label": "Slovakia",
    "code": "SVK"
  },
  {
    "label": "Slovenia",
    "code": "SVN"
  },
  {
    "label": "Solomon Islands",
    "code": "SLB"
  },
  {
    "label": "Somalia",
    "code": "SOM"
  },
  {
    "label": "South Africa",
    "code": "ZAF"
  },
  {
    "label": "South Korea",
    "code": "KOR"
  },
  {
    "label": "South Sudan",
    "code": "SSD"
  },
  {
    "label": "Spain",
    "code": "ESP"
  },
  {
    "label": "Sri Lanka",
    "code": "LKA"
  },
  {
    "label": "St. Vincent & Grenadines",
    "code": "VCT"
  },
  {
    "label": "State of Palestine",
    "code": "PSE"
  },
  {
    "label": "Sudan",
    "code": "SDN"
  },
  {
    "label": "Suriname",
    "code": "SUR"
  },
  {
    "label": "Sweden",
    "code": "SWE"
  },
  {
    "label": "Switzerland",
    "code": "CHE"
  },
  {
    "label": "Syria",
    "code": "SYR"
  },
  {
    "label": "Tajikistan",
    "code": "TJK"
  },
  {
    "label": "Tanzania",
    "code": "TZA"
  },
  {
    "label": "Thailand",
    "code": "THA"
  },
  {
    "label": "Timor-Leste",
    "code": "TLS"
  },
  {
    "label": "Togo",
    "code": "TGO"
  },
  {
    "label": "Tonga",
    "code": "TON"
  },
  {
    "label": "Trinidad and Tobago",
    "code": "TTO"
  },
  {
    "label": "Tunisia",
    "code": "TUN"
  },
  {
    "label": "Turkey",
    "code": "TUR"
  },
  {
    "label": "Turkmenistan",
    "code": "TKM"
  },
  {
    "label": "Tuvalu",
    "code": "TUV"
  },
  {
    "label": "Uganda",
    "code": "UGA"
  },
  {
    "label": "Ukraine",
    "code": "UKR"
  },
  {
    "label": "United Arab Emirates",
    "code": "ARE"
  },
  {
    "label": "United Kingdom",
    "code": "GBR"
  },
  {
    "label": "United States",
    "code": "USA"
  },
  {
    "label": "Uruguay",
    "code": "URY"
  },
  {
    "label": "Uzbekistan",
    "code": "UZB"
  },
  {
    "label": "Vanuatu",
    "code": "VUT"
  },
  {
    "label": "Venezuela",
    "code": "VEN"
  },
  {
    "label": "Vietnam",
    "code": "VNM"
  },
  {
    "label": "Yemen",
    "code": "YEM"
  },
  {
    "label": "Zambia",
    "code": "ZMB"
  },
  {
    "label": "Zimbabwe",
    "code": "ZWE"
  },
];

export const QUALIFICATION_QUESTIONS = {
  "auto": [
    {
      "trackerEventCategory": "question_dashcam",
      "questionId": 1,
      "question": "Apakah mobilmu dilengkapi kamera dashboard?",
      "type": "button",
      "options": [
        {
          "tracker": "dashcam_yes",
          "label": "Ya",
          "metaQQID": "QAUTDAS01"
        },
        {
          "tracker": "dashcam_no",
          "label": "Tidak",
          "metaQQID": "QAUTDAS00"
        }
      ]
    },
    {
      "trackerEventCategory": "question_odometer",
      "questionId": 2,
      "question": "Berapa jarak tempuh mobilmu saat ini?",
      "type": "dropdown",
      "label": "Pilih jarak tempuh",
      "options": [
        {
          "tracker": "0_10k",
          "label": "0 - 10.000 Km",
          "metaQQID": "QAUTODO00"
        },
        {
          "tracker": "10_20k",
          "label": "10.001 - 20.000 Km",
          "metaQQID": "QAUTODO10"
        },
        {
          "tracker": "20_30k",
          "label": "20.001 - 30.000 Km",
          "metaQQID": "QAUTODO20"
        },
        {
          "tracker": "30_40k",
          "label": "30.001 - 40.000 Km",
          "metaQQID": "QAUTODO30"
        },
        {
          "tracker": "40_50k",
          "label": "40.001 - 50.000 Km",
          "metaQQID": "QAUTODO40"
        },
        {
          "tracker": "50_60k",
          "label": "50.001 - 60.000 Km",
          "metaQQID": "QAUTODO50"
        },
        {
          "tracker": "60_70k",
          "label": "60.001 - 70.000 Km",
          "metaQQID": "QAUTODO60"
        },
        {
          "tracker": "70_80k",
          "label": "70.001 - 80.000 Km",
          "metaQQID": "QAUTODO70"
        },
        {
          "tracker": "80_90k",
          "label": "80.001 - 90.000 Km",
          "metaQQID": "QAUTODO80"
        },
        {
          "tracker": "90k_plus",
          "label": "Lebih dari 90.000 Km",
          "metaQQID": "QAUTODO90"
        }
      ]
    },
    {
      "trackerEventCategory": "question_usage",
      "questionId": 3,
      "question": "Untuk apa kamu menggunakan mobil ini?",
      "type": "button",
      "options": [
        {
          "tracker": "personal_car",
          "label": "Keperluan Pribadi",
          "metaQQID": "QAUTUSE01"
        },
        {
          "tracker": "business_car",
          "label": "Keperluan Bisnis (taksi online, transport barang, rental, dll)",
          "metaQQID": "QAUTUSE02"
        }
      ]
    },
    {
      "trackerEventCategory": "question_commute",
      "questionId": 4,
      "question": "Apakah mobilmu digunakan untuk pergi/pulang bekerja?",
      "type": "button",
      "options": [
        {
          "tracker": "commute_yes",
          "label": "Ya",
          "metaQQID": "QAUTCOM01"
        },
        {
          "tracker": "commute_no",
          "label": "Tidak",
          "metaQQID": "QAUTCOM00"
        }
      ]
    },
    {
      "trackerData": {
        "0": "0_claim",
        "1": "1_claim",
        "2": "2_claim",
        "3 atau lebih": "3_plus_claim"
      },
      "metaQQID": {
        "0": "QAUTCLM00",
        "1": "QAUTCLM01",
        "2": "QAUTCLM02",
        "3 atau lebih": "QAUTCLM03"
      },
      "trackerEventCategory": "question_claim",
      "questionId": 5,
      "question": "Apakah ada klaim yang diajukan untuk mobilmu dalam waktu 12 bulan terakhir?",
      "type": "button",
      "options": [
        {
          "tracker": "0_claim",
          "label": "0",
          "metaQQID": "QAUTCLM00"
        },
        {
          "tracker": "1_claim",
          "label": "1",
          "metaQQID": "QAUTCLM01"
        },
        {
          "tracker": "2_claim",
          "label": "2",
          "metaQQID": "QAUTCLM02"
        },
        {
          "tracker": "3_plus_claim",
          "label": "3 atau lebih",
          "metaQQID": "QAUTCLM03"
        }
      ]
    },
    {
      "trackerEventCategory": "question_gender_status",
      "questionId": 6,
      "question": "Apa jenis kelamin dan status pernikahan pengemudi?",
      "type": "button",
      "options": [
        {
          "tracker": "male_single",
          "label": "Pria Lajang",
          "metaQQID": "QAUTGENMS"
        },
        {
          "tracker": "male_married",
          "label": "Pria Sudah Menikah",
          "metaQQID": "QAUTGENMM"
        },
        {
          "tracker": "female_single",
          "label": "Wanita Lajang",
          "metaQQID": "QAUTGENFS"
        },
        {
          "tracker": "female_married",
          "label": "Wanita Sudah Menikah",
          "metaQQID": "QAUTGENFM"
        }
      ]
    },
    {
      "trackerData": {},
      "metaQQID": "QAUTDOB",
      "trackerEventCategory": "question_birthdate",
      "questionId": 7,
      "question": "Kapan tanggal lahir pengemudi?",
      "type": "date"
    },
    {
      "trackerEventCategory": "question_license",
      "questionId": 8,
      "question": "Sudah berapa lama pengemudi memiliki SIM?",
      "type": "button",
      "options": [
        {
          "tracker": "less_1_year",
          "label": "Kurang dari 1 tahun",
          "metaQQID": "QAUTLCN00"
        },
        {
          "tracker": "1_year",
          "label": "1",
          "metaQQID": "QAUTLCN01"
        },
        {
          "tracker": "2_years",
          "label": "2",
          "metaQQID": "QAUTLCN02"
        },
        {
          "tracker": "3_years",
          "label": "3",
          "metaQQID": "QAUTLCN03"
        },
        {
          "tracker": "4_years",
          "label": "4",
          "metaQQID": "QAUTLCN04"
        },
        {
          "tracker": "5_years",
          "label": "5",
          "metaQQID": "QAUTLCN05"
        },
        {
          "tracker": "6_plus_years",
          "label": "6 atau lebih",
          "metaQQID": "QAUTLCN06"
        }
      ]
    },
    {
      "trackerEventCategory": "question_multicar",
      "questionId": 9,
      "question": "Apakah kamu ada rencana untuk mengasuransikan lebih dari 1 mobil?",
      "type": "button",
      "options": [
        {
          "tracker": "2_cars",
          "label": "Ya, mobil ini + 1 lainnya",
          "metaQQID": "QAUTMUL02"
        },
        {
          "tracker": "3_cars",
          "label": "Ya, mobil ini + 2 lainnya",
          "metaQQID": "QAUTMUL03"
        },
        {
          "tracker": "3_plus_cars",
          "label": "Ya, mobil ini + beberapa lainnya",
          "metaQQID": "QAUTMUL04"
        },
        {
          "tracker": "1_car",
          "label": "Tidak, mobil ini saja",
          "metaQQID": "QAUTMUL01"
        }
      ]
    },
    {
      "trackerEventCategory": "question_policy_expired",
      "questionId": 10,
      "question": "Kapan polis asuransi untuk mobilmu berakhir?",
      "metaQQID": "QAUTEXP",
      "type": "datedropdownbutton",
      "options": [
        {
          "tracker": "uninsured",
          "label": "Mobil ini sedang tidak diasuransikan",
          "metaQQID": "QAUTEXP00"
        }
      ],
      "optionYears": [
        {
          "label": "2025",
          "value": "2025"
        },
        {
          "label": "2026",
          "value": "2026"
        },
        {
          "label": "2027",
          "value": "2027"
        },
        {
          "label": "2028",
          "value": "2028"
        },
        {
          "label": "2029",
          "value": "2029"
        }
      ],
      "optionMonths": [
        {
          "label": "Januari",
          "value": "01"
        },
        {
          "label": "Februari",
          "value": "02"
        },
        {
          "label": "Maret",
          "value": "03"
        },
        {
          "label": "April",
          "value": "04"
        },
        {
          "label": "Mei",
          "value": "05"
        },
        {
          "label": "Juni",
          "value": "06"
        },
        {
          "label": "Juli",
          "value": "07"
        },
        {
          "label": "Agustus",
          "value": "08"
        },
        {
          "label": "September",
          "value": "09"
        },
        {
          "label": "Oktober",
          "value": "10"
        },
        {
          "label": "November",
          "value": "11"
        },
        {
          "label": "Desember",
          "value": "12"
        }
      ]
    },
    {
      "trackerEventCategory": "question_contact_preference",
      "questionId": 11,
      "question": "Bagaimana kamu ingin dihubungi untuk mendapat penawaran terbaik dan pembelian polis?",
      "type": "button",
      "options": [
        {
          "tracker": "call",
          "label": "Telepon",
          "metaQQID": "QAUTCON01"
        },
        {
          "tracker": "whatsapp_chat",
          "label": "Chat WhatsApp",
          "metaQQID": "QAUTCON02"
        },
        {
          "tracker": "later",
          "label": "Nanti Saja",
          "metaQQID": "QAUTCON00"
        }
      ]
    }
  ],
  "health": [
    {
      "trackerEventCategory": "Q_insured",
      "questionId": 1,
      "question": "Hari ini kamu sedang mencari asuransi untuk siapa? (Pilih  yang sesuai)",
      "type": "button",
      "options": [
        {
          "tracker": "personal",
          "label": "Saya sendiri",
          "metaQQID": "QPMIINS01"
        },
        {
          "tracker": "family",
          "label": "Saya & keluarga",
          "metaQQID": "QPMIINS02"
        }
      ]
    },
    {
      "type": "member_form",
      "questionId": 2,
      "options": {
        "2a": {
          "question": "Mohon lengkapi data dirimu:",
          "forms": [
            "myself",
          ],
        },
        "2b": {
          "question": "Mohon lengkapi data berikut untuk semua yang ingin kamu asuransikan",
          "forms": [
            "myself_family",
            "spouse",
            "boys",
            "girls",
          ],
        },
      },
      "formTypes": [
        {
          "trackerEventCategory": "Q_personal",
          "name": "myself",
          "fields": [
            "gender",
            "martial_status",
            "dob",
            "height",
            "weight",
          ],
        },
        {
          "trackerEventCategory": "Q_family_self",
          "name": "myself_family",
          "fields": [
            "gender",
            "dob",
            "height",
            "weight",
          ],
        },
        {
          "trackerEventCategory": "Q_family_partner",
          "name": "spouse",
          "fields": [
            "gender",
            "dob",
            "height",
            "weight",
          ],
        },
        {
          "trackerEventCategory": "Q_family_son",
          "name": "boys",
          "fields": [
            "dob",
            "height",
            "weight",
          ],
        },
        {
          "trackerEventCategory": "Q_family_daughter",
          "name": "girls",
          "fields": [
            "dob",
            "height",
            "weight",
          ],
        }
      ]
    },
    {
      "trackerEventCategory": "Q_income",
      "questionId": 3,
      "question": [
        "Berapa pendapatan bulanan kamu?",
        "Berapa pendapatan bulanan rumah tanggamu? (Pendapatan rumah tangga = gabungan pendapatan suami & istri)",
      ],
      "type": "button",
      "options": [
        {
          "tracker": "1_10",
          "label": "Sekitar 1-10 juta per bulan",
          "metaQQID": ""
        },
        {
          "tracker": "11_20",
          "label": "Sekitar 11-20 juta per bulan",
          "metaQQID": ""
        },
        {
          "tracker": "21_30",
          "label": "Sekitar 21-30 juta per bulan",
          "metaQQID": ""
        },
        {
          "tracker": "31_50",
          "label": "Sekitar 31-50 juta per bulan",
          "metaQQID": "QHEABUD11"
        },
        {
          "tracker": "51_100",
          "label": "Sekitar 51-100 juta per bulan",
          "metaQQID": ""
        },
        {
          "tracker": "101_plus",
          "label": "Di atas 101 juta per bulan",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_postal",
      "questionId": 4,
      "question": "Apa kode pos tempat tinggal kamu saat ini?",
      "type": "text",
      "maxLength": "5",
      "label": "Kode pos",
    },
    {
      "trackerEventCategory": "Q_nationality",
      "questionId": 5,
      "question": "Apa kewarganegaraanmu?",
      "type": "dropdown_button",
      "label": "Pilih kewarganegaraanmu",
      "options": [
        {
          "tracker": "wni",
          "label": "Indonesia",
          "metaQQID": "QPMINATIND"
        }
      ],
      "dropdown": {
        "tracker": "wna",
        "label": "Asing",
        "metaQQID": "QPMINATEXP",
        "options": COUNTRIES
      }
    },
    {
      "trackerEventCategory": "Q_profession",
      "type": "button",
      "questionId": 6,
      "question": (person) => `Apakah ${person} bekerja di salah satu profesi berikut ini?`,
      "questionsList": [
        "Pengacara",
        "Tokoh Publik",
        "Tokoh Agama",
        "Tenaga Medis",
        "Atlet Profesional",
        "Penyelam",
        "Pekerja Pabrik Kimia",
        "Ekstraksi Minyak & Gas",
        "Kehutanan & Penebangan",
        "Tukang Las",
        "Pekerja Di Bawah Tanah atau Dataran Tinggi",
        "Pekerja Pabrik Bahan Peledak",
        "Operator Alat Berat",
        "Pabrik Peleburan",
        "Pekerja Batu Bara atau Asbes",
        "Awak Kapal (Stasioner atau Lepas Pantai)",
        "Pekerja Pembangkit Listrik/Pabrik Nuklir",
        "Pekerja Pabrik Pembuatan Alat Medis",
      ],
      "options": [
        {
          "tracker": "profession_yes",
          "label": "Ya",
          "metaQQID": ""
        },
        {
          "tracker": "profession_no",
          "label": "Tidak",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_benefit",
      "questionId": 7,
      "question": "Manfaat apa yang sedang kamu cari sesuai anggaranmu?",
      "type": "button",
      "options": [
        {
          "tracker": "inpatient",
          "label": "Rawat Inap Saja (plan ekonomis)",
          "metaQQID": "QPMIBENINP"
        },
        {
          "tracker": "full",
          "label": "Rawat Inap + Rawat Jalan (plan premium)",
          "metaQQID": "QPMIBENFULL"
        }
      ]
    },
    {
      "trackerEventCategory": "Q_country",
      "questionId": 8,
      "question": "Wilayah pertanggungan mana yang kamu inginkan sesuai anggaranmu?",
      "type": "button",
      "options": [
        {
          "tracker": "id",
          "label": "Indonesia saja",
          "metaQQID": "QPMICTYIDN"
        },
        {
          "tracker": "id_my_sg",
          "label": "Indonesia, Malaysia dan Singapura",
          "metaQQID": "QPMICTYSEA"
        },
        {
          "tracker": "asia",
          "label": "Asia",
          "metaQQID": "QPMICTYASIA"
        },
        {
          "tracker": "worldwide",
          "label": "Seluruh dunia",
          "metaQQID": "QPMICTYGLOB"
        }
      ]
    },
    {
      "trackerEventCategory": "Q_active_insurance",
      "questionId": 9,
      "question": "Apakah saat ini kamu sudah terlindungi asuransi?",
      "type": "button",
      "options": [
        {
          "tracker": "uninsured",
          "label": "Tidak sama sekali",
          "metaQQID": "QPMIACT00"
        },
        {
          "tracker": "bpjs",
          "label": "BPJS saja",
          "metaQQID": "QPMIACT01"
        },
        {
          "tracker": "employee_benefit",
          "label": "Asuransi kantor",
          "metaQQID": "QPMIACT02"
        },
        {
          "tracker": "insured",
          "label": "Asuransi pribadi",
          "metaQQID": "QPMIACT03"
        }
      ]
    },
    {
      "trackerEventCategory": "Q_smoking",
      "questionId": 10,
      "question": (person) => `Berapa banyak batang rokok yang ${person} hisap dalam sehari?`,
      "type": "button",
      "options": [
        {
          "tracker": "cig_10_plus",
          "label": "Lebih dari 10 batang rokok",
          "metaQQID": ""
        },
        {
          "tracker": "cig_1_10",
          "label": "1-10 batang rokok",
          "metaQQID": ""
        },
        {
          "tracker": "cig_0",
          "label": "Tidak merokok",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_treatment",
      "questionId": 11,
      "question": (person) => `Apakah ${person} saat ini sedang <span class='red-text'>menjalani perawatan</span>, <span class='red-text'>didiagnosis</span>, atau pernah <span class='red-text'>disarankan untuk menjalani pengobatan</span> kondisi medis tertentu?`,
      "type": "button",
      "options": [
        {
          "tracker": "treatment_undergoing",
          "label": "Ya, sedang menjalani perawatan",
          "metaQQID": ""
        },
        {
          "tracker": "treatment_diagnosed",
          "label": "Ya, telah didiagnonis",
          "metaQQID": ""
        },
        {
          "tracker": "treatment_recommended",
          "label": "Ya, pernah disarankan untuk menjalankan perawatan",
          "metaQQID": ""
        },
        {
          "tracker": "treatment_no",
          "label": "Tidak, tidak ada kondisi medis tertentu",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_medical_history",
      "questionId": 12,
      "question": (person) => `Apakah ${person} pernah <span class='red-text'>memiliki riwayat atau menerima saran kesehatan untuk kondisi medis tertentu</span>, baik yang terdiagnosis atau belum?`,
      "type": "button",
      "options": [
        {
          "tracker": "history_yes",
          "label": "Ya, memiliki riwayat kondisi medis tertentu",
          "metaQQID": ""
        },
        {
          "tracker": "history_recommended",
          "label": "Ya, pernah menerima saran kesehatan untuk kondisi medis tertentu",
          "metaQQID": ""
        },
        {
          "tracker": "history_no",
          "label": "Tidak, tidak memiliki riwayat atau menerima saran kesehatan untuk kondisi medis tertentu",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_hospitalized",
      "questionId": 13,
      "question": (person) => `Apakah ${person} pernah <span class='red-text'>dirawat di rumah sakit lebih dari 3 hari berturut-turut</span> karena penyakit apapun (kecuali kecelakaan) dalam 5 tahun terakhir?`,
      "type": "button",
      "options": [
        {
          "tracker": "hospitalized_yes",
          "label": "Ya, pernah",
          "metaQQID": ""
        },
        {
          "tracker": "hospitalized_no",
          "label": "Tidak, tidak pernah",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_family_medical_history",
      "questionId": 14,
      "question": (person) => `Apakah orang tua ${person} pernah <span class='red-text'>didiagnosis</span> atau <span class='red-text'>meninggal</span> karena penyakit kanker, jantung, stroke, atau penyakit lainnya <span class='red-text'>sebelum usia 60 tahun</span>?`,
      "type": "button",
      "options": [
        {
          "tracker": "fmh_diagnosed",
          "label": "Ya, ada keluarga yang pernah didiagnosis penyakit tersebut",
          "metaQQID": ""
        },
        {
          "tracker": "fmh_died",
          "label": "Ya, ada keluarga yang meninggal sebelum usia 60 tahun  karena penyakit tersebut",
          "metaQQID": ""
        },
        {
          "tracker": "fmh_no",
          "label": "Tidak ada",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_medical_test",
      "questionId": 15,
      "question": (person) => `Apakah ${person} <span class='red-text'>sedang menunggu hasil tes</span> atau <span class='red-text'>melakukan pemeriksaan untuk kondisi medis tertentu</span> yang belum terdiagnosis, atau <span class='red-text'>sedang menunggu jadwal tes</span> yang direkomendasikan oleh dokter?`,
      "type": "button",
      "options": [
        {
          "tracker": "test_result",
          "label": "Ya, sedang menunggu hasil tes",
          "metaQQID": ""
        },
        {
          "tracker": "test_ongoing",
          "label": "Ya, sedang melakukan pemeriksaan",
          "metaQQID": ""
        },
        {
          "tracker": "test_schedule",
          "label": "Ya, sedang menunggu jadwal tes yang direkomendasikan oleh dokter",
          "metaQQID": ""
        },
        {
          "tracker": "test_no",
          "label": "Tidak, tidak sedang menunggu hasil tes atau pemeriksaan apapun",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_abnormalities",
      "questionId": 16,
      "question": (person) => `Apakah ${person} <span class='red-text'>memiliki hasil tes abnormal</span> tapi belum terdiagnosis sebagai penyakit atau gangguan?`,
      "type": "button",
      "options": [
        {
          "tracker": "abnomalities_yes",
          "label": "Ya, ada hasil tes abnormal",
          "metaQQID": ""
        },
        {
          "tracker": "abnormalities_no",
          "label": "Tidak, tidak ada hasil tes abnormal",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_undiagnosed",
      "questionId": 17,
      "question": (person) => `Apakah ${person} memiliki <span class='red-text'>gejala, kelainan, cacat fisik, cedera, atau penyakit</span> yang belum pernah didiskusikan dengan dokter, atau  berencana untuk <span class='red-text'>mencari pengobatan</span>, pemeriksaan medis, atau <span class='red-text'>tes diagnosis</span> (selain untuk keperluan pekerjaan rutin atau kehamilan) yang belum disebutkan dalam pertanyaan sebelumnya?`,
      "type": "button",
      "options": [
        {
          "tracker": "undiagnosed_issues",
          "label": "Ya, memiliki gejala, kelainan, cacat fisik, cedera, atau penyakit yang belum pernah didiskusikan dengan dokter",
          "metaQQID": ""
        },
        {
          "tracker": "undiagnosed_consultation",
          "label": "Ya, berencana untuk berkonsultasi atau menjalani pengobatan/pemeriksaan",
          "metaQQID": ""
        },
        {
          "tracker": "undiagnosed_check",
          "label": "Ya, berencana untuk mencari pengobatan, pemeriksaan medis, atau tes diagnosis",
          "metaQQID": ""
        },
        {
          "tracker": "undiagnosed_no",
          "label": "Tidak sama sekali",
          "metaQQID": ""
        }
      ]
    },
    {
      "trackerEventCategory": "Q_contact_preference",
      "questionId": 18,
      "question": "Bagaimana kamu ingin dihubungi untuk mendapatkan penawaran dan pembelian polis?",
      "type": "button",
      "options": [
        {
          "tracker": "call",
          "label": "Melalui Telepon",
          "metaQQID": "QPMICON01"
        },
        {
          "tracker": "whatsapp_chat",
          "label": "Melalui Chat WhatsApp",
          "metaQQID": "QPMICON02"
        },
        {
          "tracker": "later",
          "label": "Nanti Saja",
          "metaQQID": "QPMICON00"
        }
      ]
    }
  ]
};

export const FORM_TYPE_LABELS = {
  "myself_family": "Saya Sendiri",
  "spouse": "Pasangan",
  "boys": "Anak Laki - Laki",
  "girls": "Anak Perempuan",
}

export const GENDER_OPTIONS = [
  { label: 'Pria', value: 'MALE' },
  { label: 'Wanita', value: 'FEMALE' }
];

export const MARITAL_STATUS_OPTIONS = [
  { label: 'Lajang', value: 'SINGLE' },
  { label: 'Menikah', value: 'MARRIED' }
];
